import { useEffect, useState } from 'react';
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactWebChat from 'botframework-webchat';
import { useConfiguration } from '../../contexts/ConfigurationContext';
import { useQuestionnaireContext } from '../../contexts/QuestionnaireContext';
import { useWebchat } from './useWebchat';
import { t } from '@lingui/macro';
import { useLocalization } from '../../contexts/LocalizationContext';
import { useSessionContext } from '../../contexts/SessionContext';
import { Hero } from '../Hero/Hero';
import { useHistory } from 'react-router';
import { Loader } from '../Loader';
import { QuitConfirmation } from '../Dialogs/QuitConfrmation';

export const Questionnaire = () => {
  const { locale: patientLocale } = useLocalization();
  const { startSession, patientInfo } = useSessionContext();
  const { questionnaire, conversationParameters, onIncomingActivity, questionnaireComplete, showThankYou, setShowThankYou } = useQuestionnaireContext();
  const { configuration, cognitiveServicesConfiguration } = useConfiguration();
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const history = useHistory();

  const { initializeWebchat, updateWebchat, webchatConfig, webchatConnected } = useWebchat(patientLocale, configuration?.webchat.defaultScenarioId, conversationParameters?.token, onIncomingActivity);

  useEffect(() => {
    const func = async () => {
      if (patientInfo && !questionnaire) {
        await startSession(patientLocale, patientInfo);
        setLoading(false);
      }
    };
    func();
  }, [patientInfo]);

  useEffect(() => {
    const func = () => {
      if (conversationParameters) {
        if (!webchatConfig || !webchatConnected) {
          // console.log('init webchat');
          initializeWebchat(configuration, conversationParameters);
        } else {
          // console.log('update webchat');
          updateWebchat(cognitiveServicesConfiguration);
        }
        setLoading(false);
      }
    };
    func();
  }, [configuration, conversationParameters, cognitiveServicesConfiguration]);

  const onQuit = () => {
    window.location.href = '/';
  };

  return (
    <>
      <Hero title={t({ id: 'Questionnaire.Title' })} showSessionInfo />
      <Loader active={loading}>
        {webchatConfig && questionnaire && !showThankYou && <>
          <Container id='content' className='h-100' style={{ overflowY: 'auto' }}>
            <ReactWebChat {...webchatConfig} locale={patientLocale} role="main" />
          </Container>
        </>}

        {questionnaireComplete && !showThankYou && <Container id='content' className='d-flex flex-row-reverse mt-2'>
          <Button size='lg' color='primary' onClick={() => setShowThankYou(true)}>{t({ id: 'Common.Continue' })}</Button>
        </Container>}

        {showThankYou && <Container id='content' className='d-flex flex-column'>
          <ul className='hero-list'>
            <li>{t({ id: 'Questionnaire.Complete.1' })}</li>
            <li>{t({ id: 'Questionnaire.Complete.2' })}</li>
            <li>{t({ id: 'Questionnaire.Complete.3' })}</li>
            <li>{t({ id: 'Questionnaire.Complete.4' })}</li>
          </ul>
          <div className='align-self-md-end d-flex flex-column flex-md-row' style={{ columnGap: '1rem', rowGap: '1rem' }}>
            <Button color='danger' size='lg' onClick={() => setModalOpen(true)}>{t({ id: 'Questionnaire.Complete.Quit' })}</Button>
            <Button color='primary' size='lg' onClick={() => history.push('/transcript')}>{t({ id: 'Questionnaire.Complete.Download' })}</Button>
            <Button color='primary' size='lg' onClick={() => history.push('/conversation')}>{t({ id: 'Questionnaire.Complete.Continue' })}</Button>
          </div>
          <QuitConfirmation isOpen={modalOpen} onCancel={() => setModalOpen(false)} onQuit={onQuit} />
        </Container>}
      </Loader>
    </>
  );
};
