import { useState } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useSessionContext } from "../../contexts/SessionContext";
import { useConversationApi } from "../../lib/conversationApi";
import { useTranscriptApi } from "../../lib/transcriptApi";

export const useConversation = () => {
  const { session, token } = useSessionContext();

  const { locale: userLocale } = useLocalization();
  const patientLocale = userLocale;
  const doctorLocale = userLocale;

  const [messages, setMessages] = useState([]);

  const conversationApi = useConversationApi();
  const transcriptApi = useTranscriptApi();

  const loadConversation = async () => {
    const response = await transcriptApi.getTranscriptAsync(session.sessionCode, token);
    const transcript = response.transcript.conversation.messages;

    setMessages(transcript.map((message, index) => ({
      id: index + 1,
      doctor: message.translations[doctorLocale],
      patient: message.translations[patientLocale],
      fromDoctor: message.sender == 'Doctor',
      fromPatient: message.sender == 'Patient',
      originalLocale: message.locale
    })));
  };

  const sendPatientMessage = async (content) => {
    const response = await conversationApi.sendMessageAsync(session.sessionCode, {
      senderType: 'Patient',
      sender: 'Patient',
      content: content,
      locale: patientLocale
    }, token);

    let translatedContent = content;
    if (doctorLocale != patientLocale) {
      translatedContent = response.translations[doctorLocale];
    }

    const message = {
      id: (messages?.length ?? 0) + 1,
      patient: content,
      doctor: translatedContent,
      fromPatient: true,
      fromDoctor: false,
      originalLocale: patientLocale
    };

    setMessages([...messages, message]);

    return response;
  };

  const sendDoctorMessage = async (content) => {
    const response = await conversationApi.sendMessageAsync(session.sessionCode, {
      senderType: 'Doctor',
      sender: 'Doctor',
      content: content,
      locale: doctorLocale
    }, token);

    let translatedContent = content;
    if (doctorLocale != patientLocale) {
      translatedContent = response.translations[patientLocale];
    }

    const message = {
      id: (messages?.length ?? 0) + 1,
      patient: translatedContent,
      doctor: content,
      fromPatient: false,
      fromDoctor: true,
      originalLocale: doctorLocale
    };

    setMessages([...messages, message]);

    return response;
  };

  const receiveMessage = (receivedMessage) => {
    setMessages(messages => {
      const message = {
        id: (messages?.length ?? 0) + 1,
        doctor: receivedMessage.translations[doctorLocale],
        patient: receivedMessage.translations[patientLocale],
        fromDoctor: receivedMessage.sender == 'Doctor',
        fromPatient: receivedMessage.sender == 'Patient',
        originalLocale: receivedMessage.locale
      };

      return [...messages, message];
    });
  };

  return {
    messages,
    sendPatientMessage,
    sendDoctorMessage,
    receiveMessage,
    loadConversation
  };
};
