import { Container, Button, Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { t } from '@lingui/macro';
import _ from 'lodash';

export const PatientHome = () => {
  const history = useHistory();

  const onStartSession = () => {
    history.replace('/patient-info');
  };

  const onContinueSession = () => {
    history.replace('/join');
  };

  return (
    <Container id='content' className='d-flex flex-column'>
      <ul className='hero-list'>
        {_.range(6).map(x => (
          <li key={x + 1}>{t({ id: `Home.Patient.Info.${x + 1}` })}</li>
        ))}
      </ul>
      <div className='align-self-md-end d-flex flex-column flex-md-row' style={{ columnGap: '1rem', rowGap: '1rem' }}>
        <Button color='primary' size='lg' onClick={onStartSession}>{t({ id: `Home.Patient.Start` })}</Button>
        <Button color='primary' size='lg' onClick={onContinueSession}>{t({ id: `Home.Patient.Continue` })}</Button>
      </div>
    </Container>
  );
};
