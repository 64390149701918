import { useState } from 'react';
import { Form, FormGroup, Input, Label, Button, Container, FormFeedback } from 'reactstrap';
import { t } from '@lingui/macro';
import { Hero } from '../Hero/Hero';
import { useHistory } from 'react-router-dom';
import { useSessionContext } from '../../contexts/SessionContext';

export const PatientInfo = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');

  const [validationState, setValidationState] = useState({
    firstName: true,
    lastName: true,
    dateOfBirth: true
  });

  const history = useHistory();

  const { setPatientInfo } = useSessionContext();

  const submit = () => {
    const isValid = validate();
    if (isValid) {
      setPatientInfo({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        dateOfBirth: dateOfBirth.trim()
      });
      history.replace('/questionnaire');
    }
  };

  const validate = () => {
    const result = {
      firstName: firstName?.trim().length > 0 && firstName?.match('^[A-Za-z]*$'),
      lastName: lastName?.trim().length > 0 && lastName?.match('^[A-Za-z]*$'),
      dateOfBirth: dateOfBirth?.trim().length > 0
    };

    setValidationState(result);

    return result.firstName && result.lastName && result.dateOfBirth;
  };

  return (<>
    <Hero title={t({ id: 'PatientInfo.Title' })} />
    <Container className='d-flex flex-column'>
      <Form className='d-flex flex-column flex-md-row' style={{ columnGap: '12px' }}>
        <FormGroup>
          <Label>{t({ id: 'PatientInfo.FirstName' })}</Label>
          <Input className='box-shadow' placeholder={t({ id: 'PatientInfo.FirstName' })} value={firstName} onChange={e => setFirstName(e.target.value)} required invalid={!validationState.firstName} bsSize='lg' />
          <FormFeedback>{t({ id: 'Common.Validation.FirstName' })}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>{t({ id: 'PatientInfo.LastName' })}</Label>
          <Input className='box-shadow' placeholder={t({ id: 'PatientInfo.LastName' })} value={lastName} onChange={e => setLastName(e.target.value)} required pattern='[A-Za-z]' invalid={!validationState.lastName} bsSize='lg' />
          <FormFeedback>{t({ id: 'Common.Validation.LastName' })}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>{t({ id: 'PatientInfo.DateOfBirth' })}</Label>
          <Input className='box-shadow' placeholder={t({ id: 'PatientInfo.DateOfBirth' })} value={dateOfBirth} onChange={e => setDateOfBirth(e.target.value)} required type='date' invalid={!validationState.dateOfBirth} bsSize='lg' lang='en' pattern='dd' />
          <FormFeedback>{t({ id: 'Common.Validation.RequiredField' })}</FormFeedback>
        </FormGroup>
      </Form>
      <div className='align-self-md-end d-flex flex-column flex-md-row' style={{ columnGap: '1rem' }}>
        <Button color='primary' size='lg' onClick={submit}>{t({ id: 'Common.Continue' })}</Button>
      </div>
    </Container>
  </>
  );
};
