import { useEffect, useState } from 'react';
import { AudioConfig, SpeechConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import { useConfiguration } from '../contexts/ConfigurationContext';

export const useSpeechRecognizer = (locale) => {
  const [speechRecognizer, setSpeechRecognizer] = useState(null);
  const [listening, setListening] = useState(false);

  const { cognitiveServicesConfiguration } = useConfiguration();

  useEffect(() => {
    if (cognitiveServicesConfiguration) {
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
      const speechConfig = SpeechConfig.fromAuthorizationToken(cognitiveServicesConfiguration.token, cognitiveServicesConfiguration.region);
      speechConfig.speechRecognitionLanguage = locale;

      setSpeechRecognizer(new SpeechRecognizer(speechConfig, audioConfig));
    }
  }, [cognitiveServicesConfiguration]);

  const recognizeAsync = () => {
    return new Promise((resolve, reject) => {
      setListening(true);
      speechRecognizer.recognizeOnceAsync(
        result => {
          resolve(result?.text);
          setListening(false);
        },
        error => {
          reject(error);
          setListening(false);
        }
      );
    });
  };

  return {
    recognizeAsync,
    listening
  };
};
