export const useConversationApi = () => {
  const sendMessageAsync = async (sessionCode, request, token) => {
    const response = await fetch(`/api/conversations/${sessionCode}/messages`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    return await response.json();
  };

  return {
    sendMessageAsync
  };
};
