import { Hero } from '../Hero';
import { PatientHome } from './PatientHome';
import { DoctorHome } from './DoctorHome';
import { t } from '@lingui/macro';
import { useUserContext } from '../../contexts/UserContext';
import { Container, Button } from 'reactstrap';
import _ from 'lodash';
import { useQuestionnaireContext } from '../../contexts/QuestionnaireContext';
import { useSessionContext } from '../../contexts/SessionContext';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

export const Home = ({ isPatient = false, isDoctor = false }) => {
  const { setUserRole } = useUserContext();

  const { clearState: clearQuestionnaireState } = useQuestionnaireContext();
  const { clearState: clearSessionState } = useSessionContext();

  const history = useHistory();

  const onSwitchToPatient = () => {
    setUserRole('patient');
    clearAppState();
    history.push('/patient');
  };

  const onSwitchToDoctor = () => {
    setUserRole('doctor');
    clearAppState();
    history.push('/doctor');
  };

  const clearAppState = () => {
    clearQuestionnaireState();
    clearSessionState();
  };

  return (
    <div className='home'>
      <Hero title={t({ id: 'Common.AppName' })} />
      {!isPatient && !isDoctor && <>
        <Container id='content' className='d-flex flex-column align-items-stretch justify-content-center'>
          <ul className='hero-list main'>
            <li>{t({ id: `Home.Info.1` })}</li>
            <li>{t({ id: `Home.Info.2` })}</li>
            <ul>
              <li>{t({ id: `Home.Info.3` })}</li>
              <li>{t({ id: `Home.Info.4` })}</li>
            </ul>
            <li><a href='https://pacjent.gov.pl/polityka-prywatnosci'>{t({ id: 'Common.PrivacyPolicy' })}</a></li>
          </ul>
          <div className='align-self-md-end d-flex flex-column flex-md-row align-items-stretch justify-content-stretch' style={{ columnGap: '1rem', rowGap: '1rem' }}>
            <Button size='lg' color='primary' className='yellow' onClick={onSwitchToPatient}>{t({ id: 'Home.SwitchToPatient' })}</Button>
            <Button size='lg' color='primary' className='yellow' onClick={onSwitchToDoctor}>{t({ id: 'Home.SwitchToDoctor' })}</Button>
          </div>
          <footer className='d-flex mt-5 align-items-center' style={{ fontSize: '.9rem' }}>
            <div className='d-flex flex-grow-1 align-items-end'>
              <img className='my-1 me-3' src='/images/imid-logo.svg' height={48} alt='' />
              <span style={{ whiteSpace: 'pre-line' }}>{t({ id: 'Common.Credits.Imid' })}</span>
            </div>
            <Link to={'/accessibility'}>{t({ id: 'Common.AccessibilityDeclaration' })}</Link>
          </footer>
        </Container>
      </>}
      {isPatient && <PatientHome />}
      {isDoctor && <DoctorHome />}
    </div>
  );
};
