import { createContext, useContext, useState } from 'react';
import { useSessionApi } from '../lib/sessionApi';

const SessionContext = createContext(undefined);

export const SessionContextProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [token, setToken] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);

  const [patientLocale, setPatientLocale] = useState(null);
  const [doctorLocale, setDoctorLocale] = useState(null); //configuration.locales.defaultDoctorLocale
  const [joined, setJoined] = useState(null);

  const sessionApi = useSessionApi();

  const clearState = () => {
    setSession(null);
    setToken(null);
    setPatientInfo(null);
    setPatientLocale(null);
    setDoctorLocale(null);
    setJoined(null);
  };

  const startSession = async (patientLocale, patientInfo, doctorLocale = 'pl-PL') => {
    const result = await sessionApi.startSessionAsync(patientInfo, patientLocale, doctorLocale);

    setPatientLocale(patientLocale);
    setDoctorLocale(doctorLocale);

    setSession(result.session);
    setToken(result.token);
    setJoined(false);
  };

  const joinSession = async (sessionCode, accessCode) => {
    const result = await sessionApi.joinSessionAsync(sessionCode, accessCode);
    if (result) {
      const session = result.session;
      setSession(session);
      setJoined(true);
      setPatientInfo(session.patientInfo);
      setToken(result.token);

      setPatientLocale(session.patientLocale);
      setDoctorLocale(session.doctorLocale);
    }

    return result;
  };

  return (
    <SessionContext.Provider value={{
      startSession, joinSession, session, joined, token, patientLocale, doctorLocale, patientInfo, setPatientInfo,
      clearState
    }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => useContext(SessionContext);
