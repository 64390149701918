import { useEffect, useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { Link, useLocation, Prompt, useHistory } from 'react-router-dom';
import { useSessionContext } from '../contexts/SessionContext';
import { useConfiguration } from '../contexts/ConfigurationContext';
import { useLocalization } from '../contexts/LocalizationContext';
import { t } from '@lingui/macro';
import './NavMenu.css';
import { useUserContext } from '../contexts/UserContext';
import { QuitConfirmation } from './Dialogs/QuitConfrmation';

export const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const { isPatient, isDoctor } = useUserContext();
  const { session, joined } = useSessionContext();
  const { configuration } = useConfiguration();
  const { locale, setLocale } = useLocalization();

  const location = useLocation();
  const history = useHistory();

  const initialPages = ['/', '/join', '/patient-info', '/patient', '/doctor', '/accessibility'];

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  useEffect(() => {
    if (location.pathname == '/' && location?.state?.allow) {
      history.replace();
    }
  }, [location]);

  const onHome = () => {
    if (session && !initialPages.includes(location.pathname)) {
      setModalOpen(true);
    } else {
      history.push('/', {
        allow: true
      });
    }
  };

  const goHome = () => {
    setModalOpen(false);
    history.push('/', {
      allow: true
    });
  };

  return (
    <header className='d-print-none'>
      <Navbar expand="sm" container="sm" light fixed='top'>
        <NavbarBrand onClick={onHome}><img src='/images/mz-logo.svg' height={40} /></NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />

        <Collapse isOpen={!collapsed} navbar className='justify-content-end'>
          <Nav navbar>
            <NavItem>
              <NavLink tag={Link} to="#" onClick={() => {
                const anchor = document.getElementById('content');
                anchor.scrollIntoView(true);
                anchor.tabIndex = -1;
                anchor.focus();
                setTimeout(() => anchor.removeAttribute('tabIndex'), 1000);
              }} className="text-dark skip-link visually-hidden-focusable">{t({ id: 'Common.SkipToContent' })}</NavLink>
            </NavItem>
            {location.pathname != '/' && session && <>
              {isPatient && !joined && <NavItem>
                <NavLink tag={Link} className="text-dark" to="/questionnaire" active={location.pathname == '/questionnaire'}>{t({ id: 'Navigation.Questionnaire' })}</NavLink>
              </NavItem>}
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/conversation" active={location.pathname == '/conversation'}>{t({ id: 'Navigation.Conversation' })}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/transcript" active={location.pathname == '/transcript'}>{t({ id: 'Navigation.Transcript' })}</NavLink>
              </NavItem>
            </>}
            {initialPages.includes(location.pathname) && configuration.locales.supportedLocales.map(x => (
              <NavItem>
                <NavLink tag={Link} active={locale == x} to="#" onClick={() => setLocale(x)} key={x} className="text-dark">{t({ id: `Common.Locales.${x}` })}</NavLink>
              </NavItem>
            ))}
          </Nav>
          {session && !initialPages.includes(location.pathname) && <Button className='ms-md-3' color='danger' onClick={onHome}>{t({ id: 'Common.Quit' })}</Button>}
        </Collapse>

        <Prompt when message={nextLocation => {
          if (nextLocation.pathname == '/' && !initialPages.includes(location.pathname)
            && session && !nextLocation.state?.allow) {
            nextLocation.state = undefined;
            setModalOpen(true);
            return false;
          }
          return true;
        }} />
        <QuitConfirmation isOpen={modalOpen} onCancel={() => setModalOpen(false)} onQuit={goHome} />
      </Navbar>
    </header>
  );
}
