export const useSessionApi = () => {
  const startSessionAsync = async (patientInfo, patientLocale, doctorLocale) => {
    const response = await fetch(`/api/sessions`, {
      method: 'POST',
      body: JSON.stringify({
        patientInfo,
        patientLocale,
        doctorLocale
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return await response.json();
  };

  const joinSessionAsync = async (sessionCode, accessCode) => {
    const response = await fetch(`/api/sessions/${sessionCode}/join?accessCode=${accessCode}`, {
      method: 'POST'
    });

    if (response.status == 404) {
      return null;
    }

    return await response.json();
  };

  const setSummaryAsync = async (sessionCode, summary, token) => {
    const response = await fetch(`/api/sessions/${sessionCode}/summary`, {
      method: 'PUT',
      body: JSON.stringify(summary),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return await response.json();
  };

  return {
    startSessionAsync,
    joinSessionAsync,
    setSummaryAsync
  };
};
