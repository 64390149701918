export const useTranscriptApi = () => {
  const getTranscriptAsync = async (sessionCode, token) => {
    const response = await fetch(`/api/transcripts/${sessionCode}`,{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    return await response.json();
  };
  
  return {
    getTranscriptAsync
  };
};
