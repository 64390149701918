import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';

const UserContext = createContext(undefined);

export const UserContextProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!userRole) {
      if (location.pathname == '/doctor') {
        setUserRole('doctor');
        history.replace('/');
      } else if (location.pathname == '/patient') {
        setUserRole('patient');
        history.replace('/');
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ isPatient: userRole == 'patient', isDoctor: userRole == 'doctor', setUserRole }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
