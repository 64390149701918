import { useEffect, useState } from 'react';
import { createStore, createDirectLine, createCognitiveServicesSpeechServicesPonyfillFactory } from 'botframework-webchat';
import { useConfiguration } from '../../contexts/ConfigurationContext';
import { useQuestionnaireContext } from '../../contexts/QuestionnaireContext';
import { t } from '@lingui/macro';

export const useWebchat = (locale, scenarioId, token, incomingActivityHandler) => {
  const [webchatConnected, setWebchatConnected] = useState(false);
  const [webchatConfig, setWebchatConfig] = useState(null);

  // const { configuration, cognitiveServicesConfiguration } = useConfiguration();
  const { questionnaireInitialized, setQuestionnaireInitialized, questionnaireComplete } = useQuestionnaireContext();

  useEffect(() => {
    if (questionnaireComplete) {
      disableWebchat();
    }
  }, [questionnaireComplete]);

  const initializeWebchat = (configuration, data) => {
    const botConnection = createDirectLine({
      token: data.connectorToken,
      domain: configuration.webchat.directLineUri
    });

    const store = createStore({}, store => {
      return next => {
        return action => {
          if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
            onWebchatConnected(store);
          } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
            fixYesNoQuestions(action.payload.activity);
            onIncomingActivity(action.payload.activity);
          } else if (action.type === 'DIRECT_LINE/DISCONNECT_FULFILLED') {
            onWebchatDisconnected();
          }

          return next(action);
        };
      };
    });

    const config = {
      directLine: botConnection,
      store: store,
      userID: data.userId,
      // webSpeechPonyfillFactory: createWebSpeechFactory(cognitiveServicesConfiguration.token, cognitiveServicesConfiguration.region),
      // selectVoice: (voices, activity) => {
      //   const localVoices = voices.filter(x => x._lang == activity.locale);
      //   return localVoices[0];
      // },
      styleOptions: {
        botAvatarInitials: t({ id: 'Common.BotInitials' }),
        hideUploadButton: true,
        accent: '#0052a5',
        rootHeight: '100%',
        sendBoxBorderBottom: '2px solid #0052a5',
        sendBoxBorderTop: '2px solid #0052a5',
        sendBoxBorderLeft: '2px solid #0052a5',
        sendBoxBorderRight: '2px solid #0052a5',
        sendBoxButtonColor: 'white',
        sendBoxButtonShadeColor: '#0052a5',
        sendBoxPlaceholderColor: '#0052a5',
      }
    };

    if (questionnaireComplete) {
      config.disabled = true;
      config.styleOptions.hideSendBox = true;
    }

    setWebchatConfig(config);
  };

  const updateWebchat = (cognitiveServicesConfiguration) => {
    const updatedConfig = Object.assign({}, webchatConfig, {
      webSpeechFactory: createWebSpeechFactory(cognitiveServicesConfiguration.token, cognitiveServicesConfiguration.region)
    });

    setWebchatConfig(updatedConfig);
  };

  const disableWebchat = () => {
    const updatedConfig = Object.assign({}, webchatConfig, {
      disabled: true,
      styleOptions: {
        ...webchatConfig?.styleOptions,
        hideSendBox: true
      }
    });

    setWebchatConfig(updatedConfig);
  }

  const createWebSpeechFactory = (token, region) => {
    return createCognitiveServicesSpeechServicesPonyfillFactory({
      credentials: {
        authorizationToken: token,
        region: region
      }
    });
  };

  const onWebchatConnected = (store) => {
    if (!questionnaireInitialized) {
      store.dispatch({
        type: 'DIRECT_LINE/POST_ACTIVITY',
        meta: { method: 'keyboard' },
        payload: {
          activity: {
            type: 'invoke',
            name: 'InitConversation',
            locale: locale,
            value: {
              jsonWebToken: token,
              triggeredScenario: {
                trigger: scenarioId
              }
            }
          }
        }
      });

      setQuestionnaireInitialized(true);
    }
  };

  const onIncomingActivity = (activity) => {
    incomingActivityHandler(activity);
  };

  const onWebchatDisconnected = () => {
    setWebchatConnected(false);
  };

  const fixYesNoQuestions = activity => {
    const suggestedActions = activity.suggestedActions?.actions;
    if (suggestedActions?.length == 2) {
      if (suggestedActions[0].title == 'Yes') {
        suggestedActions[0] = Object.assign(suggestedActions[0], {
          type: 'messageBack',
          text: suggestedActions[0].value,
          displayText: t({ id: 'Common.Yes' }),
          title: t({ id: 'Common.Yes' })
        });
      }
      if (suggestedActions[1].title == 'No') {
        suggestedActions[1] = Object.assign(suggestedActions[1], {
          type: 'messageBack',
          text: suggestedActions[1].value,
          displayText: t({ id: 'Common.No' }),
          title: t({ id: 'Common.No' })
        });
      }
    }
  };

  return {
    webchatConnected,
    webchatConfig,
    initializeWebchat,
    updateWebchat,
    disableWebchat
  };
};
