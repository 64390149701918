import React, { useEffect, useState } from 'react';
import { Route, useLocation, useHistory } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Questionnaire } from './components/Questionnaire';
import { Conversation } from './components/Conversation';
import { Transcript } from './components/Transcript';
import { PatientInfo } from './components/PatientInfo';
import { Join } from './components/Join';
import { Accessibility } from './components/Accessibility';

import { ConfigurationContextProvider } from './contexts/ConfigurationContext';
import { SessionContextProvider } from './contexts/SessionContext';
import { QuestionnaireContextProvider } from './contexts/QuestionnaireContext';
import { LocalizationProvider } from './contexts/LocalizationContext';
import { UserContextProvider } from './contexts/UserContext';

import './custom.scss'

export const App = () => {
  const [initialized, setIniliazied] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!initialized) {
      setIniliazied(true);

      if (!['/', '/accessibility'].includes(location.pathname)) {
        history.replace('/');
      }
    }
  }, [initialized]);

  return (
    <UserContextProvider>
      <ConfigurationContextProvider>
        <LocalizationProvider>
          <SessionContextProvider>
            <QuestionnaireContextProvider>
              <Layout>
                <Route exact path='/'>
                  <Home />
                </Route>
                <Route path='/patient'>
                  <Home isPatient />
                </Route>
                <Route path='/doctor'>
                  <Home isDoctor />
                </Route>
                <Route path='/join' component={Join} />
                <Route path='/patient-info' component={PatientInfo} />
                <Route path='/questionnaire' component={Questionnaire} />
                <Route path='/conversation' component={Conversation} />
                <Route path='/transcript' component={Transcript} />
                <Route path='/accessibility' component={Accessibility} />
              </Layout>
            </QuestionnaireContextProvider>
          </SessionContextProvider>
        </LocalizationProvider>
      </ConfigurationContextProvider>
    </UserContextProvider>
  );
};
