import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button, Form, FormGroup, Input, Label, FormFeedback, Alert } from 'reactstrap';
import { Hero } from './Hero/Hero';
import { Loader } from './Loader';
import { useSessionContext } from '../contexts/SessionContext';
import { t } from '@lingui/macro';

export const Join = () => {
  const [sessionCode, setSessionCode] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const [validationState, setValidationState] = useState({
    sessionCode: true,
    accessCode: true
  });

  const { joinSession } = useSessionContext();

  const history = useHistory();

  const join = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      setNotFound(false);
      try {
        const session = await joinSession(sessionCode, accessCode);
        if (session) {
          history.replace('/conversation');
        } else {
          setNotFound(true);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const validate = () => {
    const result = {
      sessionCode: sessionCode?.trim().length > 0,
      accessCode: accessCode?.trim().length > 0
    };

    setValidationState(result);
    setNotFound(false);

    return result.sessionCode && result.accessCode;
  };

  return (<>
    <Hero title={t({ id: 'Join.Title' })} />
    <Loader active={loading}>
      <Container id='content' className='d-flex flex-column'>
        <Form className='d-flex flex-column flex-md-row' style={{ columnGap: '12px' }}>
          <FormGroup>
            <Label>{t({ id: 'Join.SessionNumber' })}</Label>
            <Input className='box-shadow' placeholder={t({ id: 'Join.SessionNumber' })} value={sessionCode} onChange={e => setSessionCode(e.target.value)} required invalid={!validationState.sessionCode} bsSize='lg' />
            <FormFeedback>{t({ id: 'Common.Validation.RequiredField' })}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>{t({ id: 'Join.AccessCode' })}</Label>
            <Input className='box-shadow' type='date' placeholder={t({ id: 'Join.AccessCode' })} value={accessCode} onChange={e => setAccessCode(e.target.value)} invalid={!validationState.accessCode} bsSize='lg' />
            <FormFeedback>{t({ id: 'Common.Validation.RequiredField' })}</FormFeedback>
          </FormGroup>
        </Form>
        <Alert color='danger' fade={false} isOpen={notFound}>Wizyta nie została znaleziona. Sprawdź wpisane dane i spróbuj jeszcze raz.</Alert>
        <div className='align-self-md-end d-flex flex-column flex-md-row' style={{ columnGap: '1rem' }}>
          <Button color='primary' size='lg' onClick={join}>{t({ id: 'Join.StartConversation' })}</Button>
        </div>
      </Container>
    </Loader>
  </>
  );
};
