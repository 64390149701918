import { Container } from 'reactstrap';
import { Hero } from "./Hero";

export const Accessibility = () => {
  return (
    <>
      <Hero title={'Deklaracja dostępności'} />
      <Container id='content' className='accessibility-container'>
        <h2 id="a11y-wstep">Deklaracja dostępności serwisu LikarPL</h2>
        <p><strong id="a11y-podmiot">Ministerstwo Zdrowia</strong> zobowiązuje się zapewnić dostępność strony internetowej <strong>https://likar.mz.gov.pl</strong> zgodnie z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.<br />
          Oświadczenie w sprawie dostępności ma zastosowanie do <a href="https://likar.mz.gov.pl" id="a11y-url">serwisu internetowego LikarPL</a>.</p>
        <p>Data publikacji strony internetowej: <span id="a11y-data-publikacja"><strong>2022.03.31</strong></span></p>
        <p>Data ostatniej dużej aktualizacji: <span id="a11y-data-aktualizacja"><strong>2022.03.31</strong></span></p>

        <h2>Stan dostępności cyfrowej</h2>
        <p id="a11y-status">Strona internetowa jest zgodna z ustawą o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.</p>
        <p id="a11y-data-sporzadzenie">Oświadczenie sporządzono dnia <strong>2022.04.06</strong>.</p>
        <p>Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny. Na stronie internetowej można korzystać ze standardowych skrótów klawiaturowych.</p>

        <h2 id="a11y-kontakt">Informacje zwrotne i dane kontaktowe</h2>
        <ul>
          <li>Adres poczty elektronicznej <a href="mailto:internet@mz.gov.pl" id="a11y-email">internet@mz.gov.pl</a>,</li>
          <li><a href="https://www.gov.pl/web/zdrowie/koordynator-dostepnosci-w-ministerstwie-zdrowia" rel="noopener noreferrer" tabindex="-1" target="_blank" title="https://www.gov.pl/web/zdrowie/koordynator-dostepnosci-w-ministerstwie-zdrowia">Dane kontaktowe koordynatora dostępności MZ – p. Michała Mazura</a></li>
          <li>W przypadku problemów z dostępnością strony internetowej prosimy o kontakt.</li>
          <li id="a11y-procedura">Każdy ma prawo wystąpić z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Możesz też zażądać udostępnienia informacji w formach alternatywnych, na przykład odczytanie niedostępnego cyfrowo dokumentu, opisania zawartości filmu bez audiodeskrypcji itp. Żądanie powinno zawierać:
            <ul>
              <li>dane osoby zgłaszającej żądanie,</li>
              <li>wskazanie, o którą stronę internetową lub aplikację mobilną chodzi,</li>
              <li>sposób kontaktu.</li>
            </ul>
          </li>
          <li>Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji w formie alternatywnej, powinna też określić formę tej informacji.
            <ul>
              <li>Podmiot publiczny ma obowiązek zrealizować żądanie niezwłocznie i nie później, niż w ciągu 7 dni;</li>
              <li>Jeżeli nie można dotrzymać tego terminu, podmiot publiczny niezwłocznie informuje o nowym terminie, który nie może być dłuższy niż 2 miesiące;</li>
              <li>Jeśli podmiot publiczny nie może zapewnić dostępu do informacji w żądanej formie, może zaproponować alternatywny sposób dostępu do informacji.</li>
            </ul>
          </li>
        </ul>

        <p>Jeśli podmiot publiczny odmówi realizacji żądania zapewnienia dostępności lub alternatywnego dostępu do informacji, wnioskodawca ma prawo złożyć skargę.</p>
        <p>Po wyczerpaniu wszystkich możliwości skargę można przesłać także do <a href="https://www.rpo.gov.pl/content/jak-zglosic-sie-do-rzecznika-praw-obywatelskich">Rzecznika Praw Obywatelskich</a>.</p>

        <h2 id="a11y-architektura">Dostępność architektoniczna</h2>
        <ul>
          <li>
            <p><em>Ministerstwo Zdrowia ul. Miodowa 15, 00-952 Warszawa</em><br />
              <br />
              Siedziba główna: Ministerstwo Zdrowia, ul. Miodowa 15, 00-952 Warszawa<br />
              Pozostałe budynki Kancelarii znajdują się przy:</p>
            <ul>
              <li>ul Długa 38/40.</li>
            </ul>
          </li>
        </ul>

        <h3>Dostępność architektoniczna budynku przy ul. Miodowa 15</h3>
        <strong>Do obiektu można dotrzeć</strong> środkami komunikacji publicznej - przede wszystkim autobusem - przystanek Kapitulna 02 znajduje się w bezpośredniej bliskości budynku.
        Trasa dojścia do budynku: główne dojście do budynku prowadzi od strony ulicy Miodowej i pozbawione jest przeszkód. Wzdłuż fasady budynku poprowadzono oznakowanie fakturowe - ścieżkę prowadząc, zasygnalizowano wejście do budynku Ministerstwa – w postaci pasów ostrzegawczych przed drzwiami. Przed budynkiem wyznaczone są trzy publiczne miejsca parkingowe dedykowane osobom z niepełnosprawnością.
        <br />
        <p><strong>Są dwa wejścia do budynku dedykowane klientom</strong>.</p>

        <p>Wejście od strony ulicy Miodowej przez drzwi w fasadzie budynku oraz dedykowane wejście dla osób z niepełnosprawnością prowadzące przez bramę na dziedziniec budynku (wejście B). Do dziedzińca wewnętrznego i dostępnych z niego wejść A, B i C do budynku prowadzi dojście od ul. Miodowej. Od ulicy Miodowej drzwi wyróżniają się kolorystycznie na tle fasady budynku co ułatwia ich lokalizację. Obok drzwi umieszczono widoczną tablicę informującą.<br />
          W drzwiach, w narożniku znajduje się dzwonek. Informacja o uruchomieniu dzwonka odbierana jest w recepcji, dodatkowo drzwi monitorowane są przez system kamer.<br />
          Poniżej tablicy z nazwą ministerstwa umieszczono informacje o dedykowanym wejściu dla osób z niepełnosprawnościami. Wejście do budynku B znajduje się po północnej stronie centralnego dziedzińca, w narożniku skrzydeł frontowego (od ul. Miodowej) i północnego.</p>

        <p>Wejście znajduje się od strony, z której nie należy się spodziewać istotnego ruchu pieszych. Na ścianie budynku równoległej do pochylni znajduje się tablica opisująca, gdzie można uzyskać informacje dot. funkcjonowania Ministerstwa, w zależności od godziny. Tablica i napisy są duże, a czcionka czytelna i dobrze skontrastowana z tłem. Również przy drzwiach po prawej stronie, znajdują się piktogramy informujące o dostępności wejścia dla osób z niepełnosprawnością. Po minięciu szlabanu, po prawej stronie od bramy znajduje się pochylnia prowadząca do skrzydła B. Pochylnia składa się z jednego biegu.<br />
          Nawierzchnia jest szorstka i antypoślizgowa, balustrada jest z obu stron i posiada dwa pochwyty.&nbsp;</p>

        <p>Drzwi wejściowe nie są automatyczne, ale dwuskrzydłowe i zawsze jest osoba, która służy pomocą.</p>

        <p><strong>W Ministerstwie Zdrowia obowiązuje zasada, że pracownicy odbierają umówionych gości z recepcji osobiście.</strong></p>

        <p>Przy klatce schodowej w skrzydle B znajduje się <strong>jedyna w budynku winda</strong>. Drzwi windy nie są oznaczone kontrastowo w stosunku do ścian; zewnętrzny panel sterowania umieszczony na prawidłowej wysokości; posiada wypukłe klawisze, ale nie ma sygnalizacji głosowej, różnica poziomów między podłogą windy a posadzką nie jest większa niż 2 cm. Panel wewnętrzny zamontowany jest na prawidłowej wysokości i właściwie zlokalizowane w stosunku do narożnika kabiny. Przyciski oznaczone są wypukłymi cyframi i wyposażone są w informacje w alfabecie Barillea. Przycisk w panelu poziomu 0 jest wypukły, i oznaczony kolorem zielonym. Użycie przycisku „alarm”, w windzie daje sygnał dźwiękowy w kabinie i sygnał na zewnątrz. Na sygnał alarmu reaguje ochrona budynku.</p>

        <p><strong>Toaleta</strong>&nbsp;dla osób z niepełnosprawnością ruchową i poruszających się na wózku, znajduje się na parterze blisko windy – jest w trakcie modernizacji.</p>

        <p><strong>Sale konferencyjne</strong>: kinowa, kasetonowa, kolumnowa, są przystosowane dla osób poruszających się na wózku.</p>

        <p>Do budynku można wejść z psem asystującym, psem przewodnikiem lub osobą asystującą.</p>

        <p>W Instrukcji Bezpieczeństwa Pożarowego uwzględniony ewakuację osób z niepełnosprawnością.</p>

        <p>Na dziedzińcu Ministerstwa Zdrowia jest miejsce i wyznaczone miejsce postojowe dla osób ze szczególnymi potrzebami.<br />
          <br />
          Obiekt znajduje się w dość znacznym oddaleniu od przystanków komunikacji miejskiej. Od stacji metra Ratusz Arsenał dzieli go odległość około 300 metrów, a od najbliższego przystanku autobusowego Plac Krasickich 01 około 260 m.<br />
          <strong>Trasa dojścia do budynku</strong>: najbliższe dojście do budynku znajduje się od strony ulicy Długiej i prowadzi przez bramę i dziedziniec. Przed budynkiem w ramach dziedzińca zorganizowany jest parking. Nie posiada on wyznaczonych miejsc parkingowych i nie posiada miejsca parkingowego dedykowanego osobom z niepełnosprawnością. Drzwi wyróżniają się kolorystycznie na tle fasady budynku co ułatwia ich lokalizację. Przed wejściem znajdują się schody posiadające 4 stopnie.<br />
          <br />
          Drzwi wyróżniają się kolorystycznie na tle fasady budynku co ułatwia ich lokalizację. Przed wejściem znajdują się schody posiadające 4 stopnie, oraz podjazd i balustrada jest z obu stron. Komunikacja pozioma w budynku Hol i korytarze na parterze: posadzka holu wykończona jest okładziną drewnianą. Posadzki nie są śliskie. Nie odbijają światła i nie powodują olśnienia. Posadzki dobrze kontrastują z kolorystyką ścian, co ułatwia orientację w przestrzeni osobom niedowidzącym.<br />
          <br />
          <strong>Toaleta dedykowana osobom z niepełnosprawnością</strong> ulokowana jest na parterze. Budynek nie posiada windy.</p>
      </Container>
    </>
  );
};
