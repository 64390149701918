import { Button } from 'reactstrap';
import { useSessionContext } from '../../contexts/SessionContext';
import { t } from '@lingui/macro';
import { useHistory } from 'react-router';

export const PatientInfo = () => {
  const { session } = useSessionContext();
  const history = useHistory();

  const patientInfo = session?.patientInfo;

  return (
    <div className='d-flex flex-column flex-grow-1'>
      <div className='d-flex'>
        <div className='flex-grow-1'>
          <strong style={{ fontSize: '1.75rem' }}>{patientInfo?.firstName} {patientInfo?.lastName}</strong>
          <div style={{ fontWeight: 'lighter' }}>
            <span>{t({ id: 'PatientInfo.DateOfBirth' })}: </span>
            <span style={{ fontWeight: 'bold' }}>{patientInfo?.dateOfBirth}</span>
          </div>
          <div style={{ fontWeight: 'lighter' }}>
            <span>{t({ id: 'SessionInfo.Code' })}: </span>
            <span style={{ fontWeight: 'bold' }}>{session?.sessionCode}</span>
          </div>
        </div>
        <Button className='align-self-end' color='light' size='sm' onClick={() => history.push('/transcript')}>{t({ id: 'Questionnaire.Summary.Show' })}</Button>
      </div>
    </div >
  );
};
