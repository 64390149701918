import { createContext, useContext, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { messages as plMessages } from '../locales/pl';
import { messages as enMessages } from '../locales/en';
import { messages as ukMessages } from '../locales/uk';
import { messages as ruMessages } from '../locales/ru';

const LocalizationContext = createContext(undefined);

export const LocalizationProvider = ({ children }) => {
  const [locale, setLocale] = useState('uk-UA');

  useEffect(() => {
    const messages = {
      'pl': plMessages,
      'en': enMessages,
      'uk': ukMessages,
      'ru': ruMessages
    };
    i18n.load(messages);

    let actualLocale = getLanguageCode(locale);
    i18n.activate(actualLocale);

    console.log(i18n);
  }, [locale]);

  const getLanguageCode = (locale) => {
    let actualLocale = locale;
    if (!i18n._messages[actualLocale]) {
      actualLocale = new Intl.Locale(actualLocale).language;
      if (!i18n._messages[actualLocale]) {
        actualLocale = 'pl';
      }
    }

    return actualLocale;
  };

  const getString = (id, locale) => {
    const lang = getLanguageCode(locale);
    return i18n._messages[lang][id];
  }

  return (
    <LocalizationContext.Provider value={{ locale, setLocale, getString }}>
      <I18nProvider i18n={i18n}>
        {children}
      </I18nProvider>
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
