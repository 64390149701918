import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { t } from '@lingui/macro';

export const Summary = () => {
  const history = useHistory();


  return (
    <div className='d-flex flex-row justify-items-end'>
      <Button className='align-self-end' color='light' size='sm' onClick={() => history.push('/transcript')}>{t({ id: 'Questionnaire.Summary.Show' })}</Button>
    </div>
  );
};
