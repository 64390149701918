import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { t } from '@lingui/macro';
import { useUserContext } from '../../contexts/UserContext';

export const QuitConfirmation = ({ isOpen, onCancel, onQuit }) => {
  const { isPatient } = useUserContext();
  const role = isPatient ? 'Patient' : 'Doctor';

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t({ id: `Session.${role}.Quit.Confirmation.Title` })}</ModalHeader>
      <ModalBody>
        <div>{t({ id: `Session.${role}.Quit.Confirmation.Content` })}</div>
        <div className='mt-2'><strong>{t({ id: `Session.${role}.Quit.Confirmation.Reminder` })}</strong></div>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
        <Button color='danger' onClick={onQuit}>{t({ id: 'Common.Quit' })}</Button>
      </ModalFooter>
    </Modal>
  );
};
