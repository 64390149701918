import { useSessionContext } from "../../contexts/SessionContext";
import { useUserContext } from "../../contexts/UserContext";
import { t } from "@lingui/macro";

export const SessionInfo = () => {
  const { session } = useSessionContext();
  const { isPatient, isDoctor } = useUserContext();

  return (
    <div className="d-flex flex-column text-md-end">
      {session?.sessionCode && <>
        <span style={{ fontSize: '1rem' }}>{t({ id: 'SessionInfo.Code' })}</span>
        <span style={{ fontSize: '1.5rem' }}><strong>{session?.sessionCode}</strong></span>
        {isPatient && <span style={{ fontSize: '.8rem' }}>{t({ id: 'SessionInfo.Reminder' })}</span>}
      </>}
    </div>
  );
};
