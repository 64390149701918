import { createContext, useContext, useEffect, useState } from 'react';
import { useSessionContext } from './SessionContext';
import { useQuestionnaireApi } from '../lib/questionnaireApi';
import { useSessionApi } from '../lib/sessionApi';
import _ from 'lodash';

const QuestionnaireContext = createContext(undefined);

export const QuestionnaireContextProvider = ({ children }) => {
  const [conversationParameters, setConversationParameters] = useState(null);
  const [questionnaireInitialized, setQuestionnaireInitialized] = useState(false);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [questionnaireComplete, setQuestionnaireComplete] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const { session, patientInfo, token, patientLocale, doctorLocale } = useSessionContext();

  const questionnaireApi = useQuestionnaireApi();
  const sessionApi = useSessionApi();

  useEffect(() => {
    const func = async () => {
      if (patientInfo && token) {
        await startQuestionnaire(patientLocale);
      }
    };
    func();
  }, [patientInfo, token]);

  const clearState = () => {
    setConversationParameters(null);
    setQuestionnaireInitialized(false);
    setQuestionnaire(null);
    setQuestionnaireComplete(false);
    setShowThankYou(false);
  };

  const startQuestionnaire = async (locale) => {
    const result = await initializeConversation();
    setQuestionnaire({
      patientLocale: locale,
      doctorLocale
    });
    setConversationParameters(result);
  };

  const initializeConversation = async () => {
    const data = await questionnaireApi.initializeQuestionnaireAsync(token);
    return data;
  }

  const onIncomingActivity = async (activity) => {
    // if (activity.entities) {
    //   console.log(JSON.stringify(activity.entities));
    // }

    const metadata = activity.entities?.length ? activity.entities[0] : null;
    if (metadata?.summary) {
      await updateSummary(metadata.summary);
      setQuestionnaireComplete(true);
    }
  };

  const updateSummary = async (data) => {
    delete data.scenarioArgs;
    const summary = await prepareSummary(data);
    const response = await sessionApi.setSummaryAsync(session.sessionCode, summary.request, token);
  };

  const prepareSummary = async (summary) => {
    let result = {
      request: {
        locale: patientLocale,
        items: {}
      }
    };
    for (const property in summary) {
      const item = {
        title: property,
        value: null
      };

      const value = summary[property];
      if (_.isArray(value)) {
        item.value = value.map(x => _.isObject(x) ? x.value : x).join(', ');
      } else if (_.isObject(value)) {
        item.value = value?.value ?? JSON.stringify(value);
      } else {
        item.value = value;
      }

      result.request.items[property] = item.value?.toString();
    }

    return result;
  };

  return (
    <QuestionnaireContext.Provider value={{
      questionnaire, conversationParameters, onIncomingActivity, startQuestionnaire, questionnaireInitialized, setQuestionnaireInitialized, questionnaireComplete,
      showThankYou, setShowThankYou,
      clearState
    }}>
      {children}
    </QuestionnaireContext.Provider>
  );
};

export const useQuestionnaireContext = () => useContext(QuestionnaireContext);
