import { Container } from 'reactstrap';
import { t } from '@lingui/macro';
import _ from 'lodash';

export const DoctorGuideline = () => {
  return (
    <Container className='doctor-guideline-container d-flex flex-column' style={{ rowGap: '1rem' }}>
      <div>{t({ id: 'Conversation.Guidelines.Doctor.Intro' })}</div>
      <div>
        <h6>{t({ id: 'Conversation.Guidelines.Doctor.Questionnaire.Title' })}</h6>
        <ol className='mx-0 px-3 my-0'>
          {_.range(7).map(x => (
            <li key={x + 1}>{t({ id: `Conversation.Guidelines.Doctor.Questionnaire.${x + 1}` })}</li>
          ))}
        </ol>
      </div>
      <div>
        <h6>{t({ id: 'Conversation.Guidelines.Doctor.Visit.Title' })}</h6>
        <ol className='mx-0 px-3 my-0'>
          {_.range(5).map(x => (
            <li key={x + 1}>{t({ id: `Conversation.Guidelines.Doctor.Visit.${x + 1}` })}</li>
          ))}
        </ol>
      </div>
    </Container>
  );
};
