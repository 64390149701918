import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useSessionContext } from '../../contexts/SessionContext';
import { useTranscriptApi } from '../../lib/transcriptApi';
import { t } from '@lingui/macro';
import _ from 'lodash';
import { Hero } from '../Hero';
import { useUserContext } from '../../contexts/UserContext';
import { Loader } from '../Loader';
import { useLocalization } from '../../contexts/LocalizationContext';
import { TranscriptPdf } from './TranscriptPdf';

export const Transcript = () => {
  const [transcript, setTranscript] = useState(null);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);

  const { locale: userLocale, getString } = useLocalization();
  const patientLocale = userLocale;
  const doctorLocale = userLocale;

  const { isPatient, isDoctor } = useUserContext();
  const { session, token } = useSessionContext();

  const transcriptApi = useTranscriptApi();

  useEffect(() => {
    const func = async () => {
      const response = await transcriptApi.getTranscriptAsync(session.sessionCode, token);
      setTranscript(response.transcript);
      setSummary(response.summary);
      setLoading(false);
    };
    func();
  }, []);

  return (
    <>
      <Hero title={t({ id: 'Transcript.Title' })} showSessionInfo />
      <Loader active={loading}>
        <Container id='content' className="pb-1">
          {summary && <div id="summary" className='mb-5'>
            <div className='d-flex align-items-center mb-2'>
              <h2 className='flex-grow-1 mb-0'>{t({ id: 'Transcript.Summary' })}</h2>
              <PDFDownloadLink document={
                <TranscriptPdf summary={summary} transcript={transcript} locales={[session.patientLocale, userLocale, 'en-US']} getString={getString} />
              } className="btn btn-primary btn-lg" fileName={`likar-transkrypt-${session.sessionCode}.pdf`}>
                {({ blob, url, loading, error }) =>
                  loading ? t({ id: 'Transcript.LoadingDocument' }) : t({ id: 'Transcript.Download' })}
              </PDFDownloadLink>
            </div>
            <Row>
              <Col xs={12} className='summary-container mb-3'>
                <div style={{ marginBottom: '0.5em' }}>
                  <div>{t({ id: 'PatientInfo.FirstName' })}</div>
                  <div><strong>{session.patientInfo.firstName}</strong></div>
                </div>
                <div style={{ marginBottom: '0.5em' }}>
                  <div>{t({ id: 'PatientInfo.LastName' })}</div>
                  <div><strong>{session.patientInfo.lastName}</strong></div>
                </div>
                <div style={{ marginBottom: '0.5em' }}>
                  <div>{t({ id: 'PatientInfo.DateOfBirth' })}</div>
                  <div><strong>{session.patientInfo.dateOfBirth}</strong></div>
                </div>
              </Col>
            </Row>
            <Row>
              {isDoctor && <Col xs={12} className='summary-container'>
                {summary.items.map(item => (
                  <div key={item.key} style={{ marginBottom: '0.5em', breakInside: 'avoid' }}>
                    {t({ id: `Transcript.Summary.Variables.${item.key}` })}<br />
                    <strong>{item.translations[doctorLocale].value}</strong>
                  </div>
                ))}
              </Col>}
              {isPatient && <Col xs={12} className='summary-container'>
                {summary.items.map(item => (
                  <div key={item.key} style={{ marginBottom: '0.5rem', breakInside: 'avoid' }}>
                    {t({ id: `Transcript.Summary.Variables.${item.key}` })}<br />
                    <strong>{item.translations[patientLocale].value}</strong>
                  </div>
                ))}
              </Col>}
            </Row>
          </div>}

          {transcript?.conversation?.messages.length > 0 && <>
            <div id="transcript">
              <h2 className='mb-3'>{t({ id: 'Transcript.Conversation' })}</h2>
              <Row>
                {isDoctor && <Col xs={12}>
                  {transcript.conversation.messages.map(x => (
                    <div key={x.id}>
                      <div>{x.sender == 'Patient' ? <strong>{x.translations[doctorLocale]}</strong> : x.translations[doctorLocale]}</div>
                    </div>
                  ))}
                </Col>}
                {isPatient && <Col xs={12}>
                  {transcript.conversation.messages.map(x => (
                    <div key={x.id}>
                      <div>{x.sender == 'Doctor' ? <strong>{x.translations[patientLocale]}</strong> : x.translations[patientLocale]}</div>
                    </div>
                  ))}
                </Col>}
              </Row>
            </div>
          </>}
        </Container>
      </Loader>
    </>
  );
}
