import { useEffect, useState } from 'react';
import { Container, Row, Col, Input, Button, InputGroup } from 'reactstrap';
import { FaMicrophone } from 'react-icons/fa';
import { BiSend } from 'react-icons/bi';
import { Hero } from '../Hero/Hero';
import { Loader } from '../Loader';
import { DoctorGuideline } from './DoctorGuideline';
import { Tutorial } from './Tutorial';
import { useConversation } from './useConversation';
import { useSpeechRecognizer } from '../../lib/useSpeechRecognizer';
import { useSignalrConversation } from './useSignalrConversation';
import { useUserContext } from '../../contexts/UserContext';
import { t } from '@lingui/macro';
import _ from 'lodash';
import { useLocalization } from '../../contexts/LocalizationContext';

export const Conversation = ({ singleDevice = false }) => {
  const { isPatient, isDoctor } = useUserContext();

  const { locale: userLocale } = useLocalization();
  const patientLocale = userLocale;
  const doctorLocale = userLocale;

  const { messages, sendPatientMessage, sendDoctorMessage, receiveMessage, loadConversation } = useConversation();

  const [loading, setLoading] = useState(true);
  const [patientText, setPatientText] = useState('');
  const [doctorText, setDoctorText] = useState('');

  const { recognizeAsync: recognizePatient, listening: patientSpeaking } = useSpeechRecognizer(patientLocale);
  const { recognizeAsync: recognizeDoctor, listening: doctorSpeaking } = useSpeechRecognizer(doctorLocale);

  const { initialize: startSignalr, sendMessage } = useSignalrConversation(message => receiveMessage(message));

  useEffect(() => {
    const func = async () => {
      await loadConversation();
      setLoading(false);
    };
    func();
  }, []);

  useEffect(() => {
    const container = document.getElementById('messages-container');
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages, loading]);

  useEffect(() => {
    if (!singleDevice) {
      startSignalr();
    }
  }, [singleDevice]);

  const addPatientMessage = async () => {
    if (patientText.trim()) {
      const message = await sendPatientMessage(patientText.trim());

      if (!singleDevice) {
        await sendMessage(message);
      }

      setPatientText('');
    }
  };

  const addDoctorMessage = async () => {
    if (doctorText.trim()) {
      const message = await sendDoctorMessage(doctorText.trim());

      if (!singleDevice) {
        await sendMessage(message);
      }

      setDoctorText('');
    }
  };

  const patientSpeak = async () => {
    if (patientSpeaking || doctorSpeaking) return;

    const message = await recognizePatient();
    setPatientText(message);
  };

  const doctorSpeak = async () => {
    if (patientSpeaking || doctorSpeaking) return;

    const message = await recognizeDoctor();
    setDoctorText(message);
  };

  const hideTutorial = (isPatient && _.some(messages, x => x.fromPatient)) || (isDoctor && _.some(messages, x => x.fromDoctor));

  return (
    <>
      {isPatient && <>
        <Hero title={t({ id: 'Conversation.Title' })} showSessionInfo />
        <Loader active={loading}>
          <Container id='content' className='conversation-container pb-1 d-flex flex-column h-100' style={{ overflowY: 'auto' }}>
            {!hideTutorial && <Tutorial />}
            <div className='d-flex flex-column flex-grow-1 mb-2 justify-content-end' style={{ overflowY: 'auto' }}>
              <div id='messages-container' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                {messages.map(message => {
                  const sentMessage = (isPatient && message.fromPatient) || (isDoctor && message.fromDoctor);

                  return (
                    <Row key={message.id} className='message-container'>
                      {(singleDevice || isPatient) && <Col className={{
                        'sent-message': sentMessage,
                        'received-message': !sentMessage
                      }} xs={singleDevice ? 6 : 12}>
                        <span>{message.patient}</span>
                      </Col>}
                    </Row>
                  );
                })}
              </div>
            </div>
            <Row>
              {(singleDevice || isPatient) && <Col xs={singleDevice ? 6 : 12}>
                <InputGroup>
                  <Button onClick={patientSpeak} color={patientSpeaking ? 'danger' : 'primary'} className='d-flex align-items-center'><FaMicrophone /></Button>
                  <Input value={patientText} lang={patientLocale} placeholder={`${t({ id: 'Conversation.PatientMessagePlaceholder' })} (${patientLocale})`}
                    onChange={e => setPatientText(e.target.value)} onKeyDown={e => {
                      if (e.key === 'Enter') {
                        addPatientMessage();
                      }
                    }} />
                  <Button onClick={addPatientMessage} color='primary' className='d-flex align-items-center'><BiSend /></Button>
                </InputGroup>
              </Col>}
            </Row>
          </Container>
        </Loader>
      </>}

      {isDoctor && <>
        <Loader active={loading}>
          <Container className='h-100'>
            <Row className='h-100'>
              <Col className='h-100' md={6}>
                <div className='conversation-container px-1 pt-3 pb-1 d-flex flex-column h-100' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                  {!hideTutorial && <Tutorial />}
                  <div className='d-flex flex-column flex-grow-1 mb-2 justify-content-end' style={{ overflowY: 'auto' }}>
                    <div id='messages-container' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                      {messages.map(message => {
                        const sentMessage = (isPatient && message.fromPatient) || (isDoctor && message.fromDoctor);

                        return (
                          <Row key={message.id} className='message-container'>
                            {(singleDevice || isDoctor) && <Col className={{
                              'sent-message': sentMessage,
                              'received-message': !sentMessage
                            }} xs={singleDevice ? 6 : 12}>
                              <span>{message.doctor}</span>
                            </Col>}
                          </Row>
                        );
                      })}
                    </div>
                  </div>
                  <Row>
                    {(singleDevice || isDoctor) && <Col xs={singleDevice ? 6 : 12}>
                      <InputGroup>
                        <Button onClick={doctorSpeak} color={doctorSpeaking ? 'danger' : 'primary'} className='d-flex align-items-center'><FaMicrophone /></Button>
                        <Input value={doctorText} lang={doctorLocale} placeholder={`${t({ id: 'Conversation.DoctorMessagePlaceholder' })} (${doctorLocale})`}
                          onChange={e => setDoctorText(e.target.value)} onKeyDown={e => {
                            if (e.key === 'Enter') {
                              addDoctorMessage();
                            }
                          }} />
                        <Button onClick={addDoctorMessage} color='primary' className='d-flex align-items-center'><BiSend /></Button>
                      </InputGroup>
                    </Col>}
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <Hero showPatientInfo />
                {isDoctor && <DoctorGuideline />}
              </Col>
            </Row>
          </Container>
        </Loader>
      </>}

      {/* {isPatient && <Hero title={t({ id: 'Conversation.Title' })} showSessionInfo />}
      {isDoctor && <Hero showSummary showPatientInfo />}
      {isDoctor && <DoctorGuideline />}
      <Loader active={loading}>
        <Container className='conversation-container pb-1 d-flex flex-column h-100' style={{ overflowY: 'auto' }}>
          <div>instrukcja</div>
          <div className='d-flex flex-column flex-grow-1 mb-2 justify-content-end' style={{ overflowY: 'auto' }}>
            <div id='messages-container' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
              {messages.map(message => {
                const sentMessage = (isPatient && message.fromPatient) || (isDoctor && message.fromDoctor);

                return (
                  <Row key={message.id} className='mb-1'>
                    {(singleDevice || isPatient) && <Col className={{
                      'sent-message': sentMessage,
                      'received-message': !sentMessage
                    }} xs={singleDevice ? 6 : 12}>
                      <span>{message.patient}</span>
                    </Col>}
                    {(singleDevice || isDoctor) && <Col className={{
                      'sent-message': sentMessage,
                      'received-message': !sentMessage
                    }} xs={singleDevice ? 6 : 12}>
                      <span>{message.doctor}</span>
                    </Col>}
                  </Row>
                );
              })}
            </div>
          </div>
          <Row>
            {(singleDevice || isPatient) && <Col xs={singleDevice ? 6 : 12}>
              <InputGroup>
                <Button onClick={patientSpeak} color={patientSpeaking ? 'danger' : 'primary'} className='d-flex align-items-center'><FaMicrophone /></Button>
                <Input value={patientText} lang={patientLocale} placeholder={`${t({ id: 'Conversation.PatientMessagePlaceholder' })} (${patientLocale})`}
                  onChange={e => setPatientText(e.target.value)} onKeyDown={e => {
                    if (e.key === 'Enter') {
                      addPatientMessage();
                    }
                  }} />
                <Button onClick={addPatientMessage} color='primary' className='d-flex align-items-center'><BiSend /></Button>
              </InputGroup>
            </Col>}
            {(singleDevice || isDoctor) && <Col xs={singleDevice ? 6 : 12}>
              <InputGroup>
                <Button onClick={doctorSpeak} color={doctorSpeaking ? 'danger' : 'primary'} className='d-flex align-items-center'><FaMicrophone /></Button>
                <Input value={doctorText} lang={doctorLocale} placeholder={`${t({ id: 'Conversation.DoctorMessagePlaceholder' })} (${doctorLocale})`}
                  onChange={e => setDoctorText(e.target.value)} onKeyDown={e => {
                    if (e.key === 'Enter') {
                      addDoctorMessage();
                    }
                  }} />
                <Button onClick={addDoctorMessage} color='primary' className='d-flex align-items-center'><BiSend /></Button>
              </InputGroup>
            </Col>}
          </Row>
        </Container>
      </Loader> */}
    </>
  );
};
