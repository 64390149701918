export const useQuestionnaireApi = () => {
  const initializeQuestionnaireAsync = async (token) => {
    const response = await fetch(`/api/conversations`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return await response.json();
  };

  return {
    initializeQuestionnaireAsync
  }
};
