import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import _ from 'lodash';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf' },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontStyle: 'bold' }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    fontSize: 11,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 48
  },
  title: {
    fontSize: 18,
    marginVertical: 8,
    fontStyle: 'bold'
  },
  subtitle: {
    fontSize: 16,
    marginVertical: 6,
    fontStyle: 'bold'
  },
  paragraph: {
    marginBottom: 5
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  boldText: {
    fontStyle: 'bold'
  },
  regularText: {

  }
});

export const TranscriptPdf = ({ summary, transcript, locales, getString }) => {
  const uniqueLocales = _.uniq(locales);

  return (
    <Document>
      {uniqueLocales.map(locale => (<>
        <Page size="A4" style={styles.page}>
          <Text style={styles.title}>{getString('Transcript.Title', locale)}</Text>
          {summary && <>
            <Text style={styles.subtitle}>{getString('Transcript.Summary', locale)}</Text>
            {summary.items.map(item => (
              <View key={item.key} style={styles.paragraph}>
                <Text style={styles.regularText}>{getString(`Transcript.Summary.Variables.${item.key}`, locale)}</Text>
                <Text style={styles.boldText}>{item.translations[locale].value}</Text>
              </View>
            ))}
          </>}
          {transcript?.conversation?.messages.length > 0 && <>
            <Text style={styles.subtitle}>{getString('Transcript.Conversation', locale)}</Text>
            {transcript.conversation.messages.map(x => (
              <View key={x.id} style={styles.paragraph}>
                <Text style={x.sender == 'Doctor' ? styles.regularText : styles.boldText}>{x.translations[locale]}</Text>
              </View>
            ))}
          </>}
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </>))}
    </Document>
  );
};
