import { createContext, useContext, useEffect, useState } from 'react';

const ConfigurationContext = createContext(undefined);
const cognitiveServicesConfigRefreshInterval = 7 * 60 * 1000;

export const ConfigurationContextProvider = ({ children }) => {
  const [configuration, setConfiguration] = useState(null);
  const [cognitiveServicesConfiguration, setCognitiveServicesConfiguration] = useState(null);

  const [locale, setLocale] = useState(null);

  const [taskId, setTaskId] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const func = async () => {
      setCognitiveServicesConfiguration(await getCognitiveServicesConfig());

      const config = await getConfig()
      setConfiguration(config);
      setLocale(config.locales.defaultPatientLocale);

      if (taskId) {
        clearInterval(taskId);
      }
      setTaskId(setInterval(async () => {
        setCognitiveServicesConfiguration(await getCognitiveServicesConfig());
      }, cognitiveServicesConfigRefreshInterval));

      setLoaded(true);
    };
    func();
  }, []);

  const getCognitiveServicesConfig = async () => {
    const response = await fetch('api/cognitive');
    const data = await response.json();

    return data;
  };

  const getConfig = async () => {
    const response = await fetch('api/configuration');
    const data = await response.json();

    return data;
  };

  return (
    <ConfigurationContext.Provider value={{ configuration, cognitiveServicesConfiguration, locale, setLocale }}>
      {loaded && children}
    </ConfigurationContext.Provider>
  );
};

export const useConfiguration = () => useContext(ConfigurationContext);
