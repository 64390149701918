import { Container } from "reactstrap"
import { PatientInfo } from "./PatientInfo"
import { SessionInfo } from "./SessionInfo"
import { Summary } from "./Summary"

export const Hero = ({ title, children, showSessionInfo, showPatientInfo, showSummary }) => {
  return (
    <div className='hero px-2 py-3 d-flex align-items-center'>
      <Container className="d-flex flex-column flex-md-row align-items-stretch">
        {title && <h1 className="flex-grow-1 mb-0 align-self-center">{title}</h1>}
        {showPatientInfo && <PatientInfo />}
        {showSessionInfo && <SessionInfo />}
        {showSummary && <Summary />}
      </Container>
    </div>
  )
}
