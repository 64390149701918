import { useEffect, useState } from 'react';
import * as signalr from '@microsoft/signalr';
import { useSessionContext } from '../../contexts/SessionContext';

export const useSignalrConversation = (onMessageReceived) => {
  const [connection, setConnection] = useState(null);
  const { token } = useSessionContext();

  const initialize = () => {
    const connection = new signalr.HubConnectionBuilder()
      .configureLogging(signalr.LogLevel.Trace)
      .withUrl('/hubs/conversation', {
        accessTokenFactory: () => token
      })
      .build();

    connection.on('ReceiveMessage', onMessageReceived);

    setConnection(connection);
  };

  useEffect(() => {
    const func = async () => {
      if (connection) {
        await connection.start();
      }
    };
    func();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [connection]);

  const sendMessage = async (content) => {
    await connection.invoke('SendMessage', content);
  };

  return {
    initialize,
    sendMessage
  };
};
