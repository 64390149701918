import { t } from '@lingui/macro';
import _ from 'lodash';

export const Tutorial = () => {
  return (
    <div>
      <h2>{t({ id: 'Conversation.Tutorial.Title' })}</h2>
      <ul>
        {_.range(4).map(x => (
          <li key={x + 1}>{t({ id: `Conversation.Tutorial.Info.${x + 1}` })}</li>
        ))}
      </ul>
    </div>
  );
};
